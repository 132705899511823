import * as React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Container from "../components/container"

const PrivacyPolicy = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title

  return (
    <Layout
      location={location}
      title={siteTitle}
      invert
      className="bg-blue-500"
    >
      <Seo title="Privacy Policy" />
      <Container>
        <h1>Privacy Policy</h1>
        <p>Effective date: March, 2022&nbsp;</p>

        <p>
          This Online Privacy Policy &amp; Legal Statement (the
          &quot;Policy&quot;) applies to PsychicReadingServices.com,
          (&quot;PsychicReadingServices.com&quot; or the &quot;website&quot;).
          It is designed to enforce our commitment to the privacy of your
          personal information, and let you know how we collect and use it. It
          also provides certain required information to residents of the State
          of California, the European Economic Area (EEA) and the United Kingdom
          (UK). This Policy applies only to activities on
          PsychicReadingServices.com and not to any sites we may link to. By
          using the website, you accept our Policy. We may revise it at any time
          by updating this policy on our website and/or our mobile app. If you
          have any questions regarding this Policy, please contact us at: &nbsp;
        </p>

        <p>
          PsychicReadingServices.com&nbsp;
          <br />
          Re: Privacy Policy&nbsp;
          <br />
          P.O. Box 794&nbsp;
          <br />
          Langhorne, PA 19047&nbsp;
          <br />
          &nbsp;
        </p>

        <p>
          We use your data to provide and improve PsychicReadingServices.com. By
          using the website, you agree to the collection and use of information
          in accordance with this policy. &nbsp;
        </p>

        <p>
          <strong>Information Collection and Use</strong>
        </p>

        <p>
          There are circumstances in which we automatically receive and collect
          information from you. The most common sources of this information
          include:
        </p>

        <ul class="bullet-list">
          <li>Web server logs</li>

          <li>Cookies&nbsp;</li>

          <li>Pixel tags or clear GIFs</li>

          <li>Third-party web analytics services</li>

          <li>Targeted advertising</li>

          <li>
            Geolocation, including general and precise location information
          </li>

          <li>
            Device and software characteristics (such as type and
            configuration), connection information, statistics on page views,
            referring source (for example, referral URLs), IP address (which may
            tell us your general location), browser and standard web server log
            information
          </li>
        </ul>
        <p>
          For your convenience, we have provided a summary description of these
          circumstances below.&nbsp;
        </p>

        <p>
          <strong>Web Server Logs</strong>
        </p>

        <p>
          When you visit the Psychic Reading Services Website, our web servers
          may automatically log information that your browser sends us. For
          example, we may receive and collect: the name of the domain and host
          from which you access the Internet; the Internet Protocol (IP) address
          of the computer you are using; the date and time you access the
          Psychic Reading Services Website; and the Internet address of the
          website from which you linked directly to the Psychic Reading Services
          Website. We may also collect information regarding search queries run
          on the Psychic Reading Services Website. We use this information to
          monitor the usage of the Psychic Reading Services Website(s) and as
          necessary for our business. &nbsp;
        </p>

        <p>
          <strong>Cookies</strong>
        </p>

        <p>
          Like many online sites, we and/or our third-party providers use
          cookies to recognize you as you use or return to the Psychic Reading
          Services Website(s). This is done so that we can personalize and
          enhance your browsing and shopping experience. &quot;Cookies&quot; are
          small files that are placed in your browser to collect information
          about your activities on the Psychic Reading Services Website. Cookies
          help us to: (1) speed navigation, provide you with content that is
          tailored to you; (2) remember information you gave us so that you do
          not have to re-enter it; (3) determine the effectiveness of some of
          our third-party partners&#39; marketing efforts and communications;
          and (4) monitor the total number of visitors, pages viewed, and the
          total number of ads displayed. &nbsp;
        </p>

        <p>
          Browsers are typically set to create cookies automatically. You can
          choose to have your browser notify you when cookies are being written
          to your computer or accessed, or you can disable cookies entirely. By
          not using cookies, some Psychic Reading Services Website features and
          services may not function properly. &nbsp;
        </p>

        <p>
          <strong>Pixel Tags or Clear GIFs</strong>
        </p>

        <p>
          We may use sensing technologies that use pixel tags or clear GIFs
          (which are also called web beacons). These technologies allow us to
          determine the effectiveness of our e-mail and advertising and
          marketing efforts. For this purpose, we tie the pixel tags and clear
          GIFs to personally identifiable information. We may also collect
          information regarding the links within such marketing materials that
          you click on and statistics your activity on our website following
          such marketing. &nbsp;
        </p>

        <p>
          <strong>Third-party Web Analytics Services</strong>
        </p>

        <p>
          We work Service Providers (as defined below), including but not
          limited to Google Analytics, to help us better understand how you use
          the Psychic Reading Services Website. These Service Providers will
          place cookies in your browser to collect information such as how you
          were referred to the Psychic Reading Services Website, how you
          navigate around the Psychic Reading Services Website, what pages you
          view on our website, and what traffic is driven by various marketing
          methods. This information will help us to better serve you and provide
          you with more personalized information and product offerings. We do
          not allow Service Providers to collect your credit card information,
          e-mail address or password information. These Service Providers may
          only use your personal information to perform services for us and may
          not share your personal information with anyone else, or use it for
          any other purpose, except on an aggregated, non-personally
          identifiable basis. &nbsp;
        </p>

        <p>
          To learn more about the privacy practices of Google, you may review
          its privacy policy. To disable the Google Analytics cookie and other
          Service Provider cookies, you can choose to have your browser notify
          you when cookies are being written to your computer or accessed, or
          you can disable cookies entirely. You can opt out of Google Analytics
          tracking at Google Analytics&#39; currently available opt-outs for the
          web. &nbsp;
        </p>

        <p>
          <strong>Use of Data</strong>
        </p>

        <p>
          PsychicReadingServices.com uses the collected data for various
          purposes:
        </p>
        <ul class="bullet-list">
          <li>To provide and maintain the Website</li>

          <li>
            To allow you to participate in interactive features of our Website
            when you choose to do so
          </li>

          <li>To provide customer care and support</li>

          <li>
            {" "}
            To provide analysis or valuable information so that we can improve
            the Website
          </li>

          <li>To monitor the usage of the Website&nbsp;</li>

          <li>To detect, prevent and address technical issues</li>
        </ul>

        <p>
          <strong>Disclosure of Data</strong>
        </p>

        <p>Legal Requirements</p>

        <p>
          PsychicReadingServices.com may disclose any of the data described
          above that has been collected in the good faith belief that such
          action is necessary to:&nbsp;
        </p>
        <ul class="bullet-list">
          <li>To comply with a legal obligation</li>

          <li>
            To protect and defend the rights or property of
            PsychicReadingServices.com
          </li>

          <li>
            To prevent or investigate possible wrongdoing in connection with the
            use of the website
          </li>

          <li>
            To protect the personal safety of users of the website or the public
          </li>

          <li>To protect against legal liability</li>
        </ul>

        <p>
          <strong>Service Providers</strong>
        </p>

        <p>
          We may employ third-party companies and individuals to facilitate our
          Website (&ldquo;Service Providers&rdquo;), to provide the Website on
          our behalf, to perform Service-related services or to assist us in
          analyzing how our Website is used.&nbsp;
        </p>

        <p>
          These third parties have access to data collected only to perform
          these tasks on our behalf and are obligated not to disclose or use it
          for any other purpose.&nbsp;
        </p>

        <p>
          <strong>Links to Other Sites</strong>
        </p>

        <p>
          Our Website contains links to other sites that are not operated by us.
          If you click on a third-party link, you will be directed to that third
          party&rsquo;s site. We strongly advise you to review the Privacy
          Policy of every site you visit.&nbsp;
        </p>

        <p>
          We have no control over and assume no responsibility for the content,
          privacy policies or practices of any third-party sites or
          services.&nbsp;
          <br />
          &nbsp;
        </p>

        <p>
          <strong>
            Notice to Residents of the State of California, the European
            Economic Area (EEA) and the United Kingdom (UK)
          </strong>
        </p>

        <p>
          The California Consumer Privacy Act (CCPA) provides certain rights to
          those residents in the State of California with respect to the
          collection, processing and storage of their personal data. &nbsp;The
          General Data Protection Regulation (GDPR) gives similar rights to
          residents of the EEA, and the UK Data Protection Act (2018) (DPA)
          gives similar rights to residents of the UK.&nbsp;
        </p>

        <p>
          Although each piece of legislation is distinct, each offers similar
          protections to consumers or data subjects, including:&nbsp;
        </p>
        <ul class="bullet-list">
          <li>
            The right to know what personal data is being collected and
            processed, and the reason for the collection and processing
          </li>

          <li>The right to receive a copy of your personal data held by us</li>

          <li>
            The right to correct any errors in your personal data held by us
          </li>

          <li>
            The right to the deletion of your personal data (sometimes referred
            to as &ldquo;the right to be forgotten&rdquo; in the EEA and UK)
          </li>

          <li>The right to prevent the sale of your personal data</li>
        </ul>

        <p>
          &nbsp;The collection of data by this website is limited to what is
          described above (in &ldquo;Information Collection and Use&quot;) and
          we do not currently collect and/or process personal data.
          &nbsp;Therefore, we have not implemented processes to make any of the
          requests described immediately above. &nbsp;If we begin collecting
          personal data in the future, we will update this privacy policy with
          information on the procedures to follow if you wish to make any
          requests regarding your personal data at that time. Changes To This
          Privacy Policy&nbsp;
        </p>

        <p>
          We may update our Privacy Policy from time to time. We will notify you
          of any changes by posting the new Privacy Policy on this page.&nbsp;
        </p>

        <p>
          We will let you know via email and/or a prominent notice on our
          Service, prior to the change becoming effective and update the
          &ldquo;effective date&rdquo; at the top of this Privacy Policy.&nbsp;
        </p>

        <p>
          You are advised to review this Privacy Policy periodically for any
          changes. Changes to this Privacy Policy are effective when they are
          posted on this page.&nbsp;
        </p>
      </Container>
    </Layout>
  )
}

export default PrivacyPolicy

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
